import React, { useState } from 'react';
import { Link } from 'gatsby';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.css';
import Seo from '../components/Seo';

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica de autenticação aqui
  };

  return (
    <div className="login-container">
      <Seo title="Login" description="Faça login no Prontuário Fácil" />
      <main className="login-content">
        <form className="login-form" onSubmit={handleSubmit}>
          <h1>Login</h1>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="senha">Senha</label>
          <input
            type="password"
            id="senha"
            name="senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
          />
          <button type="submit" className="login-button">Entrar</button>
          <Link to="/recuperar-senha" className="forgot-password-link">Esqueceu a senha?</Link>
          <Link to="/novo-usuario" className="signup-link">Não tem uma conta? Cadastre-se</Link>
        </form>
      </main>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar closeOnClick pauseOnHover />
    </div>
  );
};

export default Login;
